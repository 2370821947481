// fonts
@mixin font-face($fontname, $filename: $fontname, $weight: normal, $style: normal) {
 @font-face {
   font-family: $fontname;
   src:url('../fonts/#{$filename}.eot');
   src:url('../fonts/#{$filename}.eot?#iefix') format('embedded-opentype'),
     url('../fonts/#{$filename}.ttf') format('truetype');
   font-weight: $weight;
   font-style: $style;
 }
}

@include font-face('Crimson Text', 'crimsontext-semibolditalic', 700, italic);
@include font-face('Crimson Text', 'crimsontext-semibold', 700, normal);
@include font-face('Crimson Text', 'crimsontext-regular', 600, normal);

@include font-face('Raleway', 'Raleway-Bold', 700, normal);
@include font-face('Raleway', 'Raleway-Regular', 500, normal);
@include font-face('Raleway', 'Raleway-Light', 300, normal);
